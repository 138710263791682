@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;700&display=swap');

:root {
  --primary: #226FBF;
  --secondary: #ffffff;
  --text: #768692;
}
body {
  margin: 0;
  padding: 20px;
  font-family: "Poppins";
  background: var(--secondary);
}
* {
  color: var(--text);
  margin: 0;
}
p {
  margin: 20px 0;
}
button {
  border: 0;
  padding: 8px;
  border-radius: 4px;
  color: var(--secondary);
  background: var(--primary);
  cursor: pointer;
}

/* navbar */
header nav {
  display: flex;
  gap: 16px;
  justify-content: center;
  max-width: 600px;
  margin: 0 auto;
  /* background-color: pink; */
}
/* header nav h1 {
  margin-right: auto;
  border-bottom: 3px solid var(--primary);
} */

.logo-header{
  display: flex;
  flex-direction: column;
  /* background-color: aqua; */
  margin-bottom: 30px;
}

.logo-header .logo-image{
  display: flex;
  flex-direction: column;
  align-self: center;
  height: 60px;
  width: 231px;
}

header nav a{
  text-decoration: none;
  padding: 6px;
  border-radius: 4px;
}
header nav a.active {
  background: lightgray;
}

/* page content */
main {
  max-width: 1200px;
  margin: 40px auto;
}


/* home */
.primary-panel {
  display: flex;
  flex-direction: column;
  gap: 16px;
  justify-content: center;
  max-width: 600px;
  margin: 0 auto;
  /* background-color: pink; */
}

.primary-panel p{
  margin-left: 0px;
  margin-right: 0px;
  margin-top: 5px;
  margin-bottom: 0px;
  /* background-color: lightblue; */
  overflow: hidden;
}

.provider-options{
  display: flex;
  flex-direction: column;
  margin: 25px;
  flex-wrap: wrap;
  max-height: 500px;
  overflow-y: auto;
  justify-content: center;
  overflow-y: auto;
}

.provider-button{
  display: flex;
  flex-direction: row;
  align-self: center;
  margin: 5px;
  width: 220px;
  min-width: 220px;
  max-width: 220px;
  background-color: white;
  border: 1px solid lightgray;
  border-radius: 5px;
}

.provider-button .provider-button-content {
  display: flex;
  flex-direction: row;
  justify-items: center;
  
}

.provider-button-content p{
  display: flex;
  flex-direction: column;
  align-self: center;
  color: darkgray;
  background-color: transparent;  
  margin-left: 10px;
  font-family: 'poppins';
  font-weight: 400;
  font-size: calc(20px + (24 - 20) * ((100vw - 1366px) / (2560 - 1366)));
}

.signInButton{
  display: flex;
  flex-direction: column;
  gap: 16px;
  justify-content: center;
  min-width: 250px;
  max-width: 250px;
  margin: 0 auto;
  /* background-color: aqua; */
}

.signin-panel{
  display: flex;
  flex-direction: column;
  align-items: center;
}

.signin-panel p{
  display: flex;
  flex-direction: column;
  text-align: center;
}

.submit-button-container{
  display: flex;
  flex-direction: column;
  align-self: center;
  justify-items: center;
  /* background-color: aqua; */
  width: 250px;
  min-width: 250px;
  max-width: 250px;
}

.p-button.p-button-info{
  display: flex;
  flex-direction: row;  
  flex-grow: 1;
  }

  .p-error{    
    align-self: center;
  }

.p-button .p-button-label {
  color: white;
}
.p-button-icon-left{
  color: white;
}
/* help layout */
.form-signup{
  display: flex;
  flex-direction: column;
  text-align: center;
}

.success-content{
  display: flex;
  flex-direction: column;
  text-align: center;
}

.auth-initiating-content{
  display: flex;
  flex-direction: column;
  text-align: center;
}
/* faq */


/* careers list */


/* careers details */


/* breadcrumbs */
